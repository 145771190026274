<template>
  <div class="d-flex my-5 align-center">
    <div>
      <div class="d-flex align-center">
        <p class="mb-0 mx-2">
          {{
            providerData?.nombre ||
            proveedorInfo?.proveedor_nombre ||
            proveedorInfo?.nombre ||
            proveedorInfo?.ganador_obs
          }}
        </p>
        <v-btn v-if="showEyeButton" @click="dialogProveedor = true" icon>
          <v-icon> mdi-eye </v-icon>
        </v-btn>
      </div>
      <v-rating
        v-if="showEyeButton"
        readonly
        half-increments
        background-color="secondary"
        color="secondary"
        length="5"
        :value="ratingProvider"
      />
    </div>
    <div v-if="!hideMount" class="mx-8 text-center">
      <p class="pa-0 ma-0">Monto</p>
      <p class="secondary--text mb-3">{{ formatearPrecio(producto.precio) }}</p>
    </div>
    <div v-if="[2].includes(tipoProceso)" class="mx-6">
    </div>
    <v-dialog v-model="dialogProveedor" width="900">
      <v-card>
        <v-card-title class="text-h6 secondary--text">
          Perfil del proveedor
        </v-card-title>

        <v-card-text>
          <InformacionProvedor :providerData="providerData" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" outlined @click="dialogProveedor = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InformacionProvedor from "@/components/InfomacionProveedorComponent.vue";

import { mapState, mapMutations } from "vuex";
export default {
  components: {
    InformacionProvedor,
  },
  props: {
    hideMount: {
      type: Boolean,
      default: false,
    },
    showEyeButton: {
      type: Boolean,
      default: true,
    },
    tipoProceso: {
      type: Number,
    }
  },
  data: () => ({
    producto: {
      precio: 0,
      titulo: "",
      obs: "",
      total: "",
      presentacion: "",
    },
    dialogProveedor: false,
    providerData: null,
    ratingProvider: 0,
  }),
  computed: {
    ...mapState("agregarInsumo", ["selectedProvider", "proveedorInfo"]),
    ...mapState("seguimientoOrden", ["seguimientoContrato"]),
  },

  watch: {},

  methods: {
    ...mapMutations("agregarInsumo", ["setProviderInfo"]),

    formatearPrecio(cantidad) {
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 8,
      }).format(cantidad);
    },
    async fetchProviderData() {
      let response = await this.services.AgreementMarco.getProviderAgreement(
        this.selectedProvider
      );
      if (response?.status == 200) {
        this.providerData = response?.data;
      }
    },
    async fetchProviderWeb() {
      let idGanador = null;
      idGanador = ([1,2,3].includes(this.tipoProceso)) ? this.proveedorInfo?.id_ganador : this.seguimientoContrato?.contrato?.id_ganador;

      if (idGanador) {
        let response = await this.services.ContratoService.getGanadorProveedorInfo(idGanador);
        if (response?.status == 200) {
          response.data.id_ganador = idGanador;
          this.setProviderInfo(response?.data);
          this.producto.precio = response?.data?.total_sub_procesos;
          if ([2,3].includes(Number(this.tipoProceso))) {
            this.$emit('setNumeroOrdenCompra', response?.data?.numero_orden_compra);
            this.$emit('setMontoAdjudicado', this.formatearPrecio(this.producto.precio).replace('$', ''));
          }
        }
      }
    },
    async fetchCalificacionProvider() {
      const idProveedor = this.providerData?.id || this.selectedProvider;
      const { data } = await this.services.Proveedores.getCalificacionProveedor(
        idProveedor
        );
        if (data) {
          this.ratingProvider = Number.parseFloat(data?.calificacion);
        }
      },
    },
    async created() {
      if (this.showEyeButton) {
        this.fetchProviderData();
        this.fetchCalificacionProvider();
        if ([1,2,3].includes(Number(this.tipoProceso))) this.fetchProviderWeb();
    } else if(!this.showEyeButton && !this.seguimientoContrato?.contrato) {
        if ([1,2,3].includes(Number(this.tipoProceso))) this.fetchProviderWeb();
    } else if (this.seguimientoContrato?.contrato) {
      this.fetchProviderWeb();
    }
  },
};
</script>