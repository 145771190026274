<template>
  <section class="pa-0">
    <v-row
      v-if="!hideFilters && !haveRole('ROLE_OFICIAL_CUMPLIMIENTO')"
      align="center"
    >
      <v-col cols="12" sm="12" md="3">
        <v-btn
          :disabled="montos.monto_restante == 0"
          color="secondary white--text"
          @click="createEntrega"
        >
          Registrar entrega
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-menu
          ref="menu"
          v-model="menuInicio"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formattedFechaInicio"
              label="Mes de inicio"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              outlined
              hide-details
              clearable
              @click:clear="setFiltroFechaInicio(null), getEtapasContrato()"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fecha_inicio"
            no-title
            scrollable
            @change="setFechaInicio"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-menu
          ref="menu"
          v-model="menuFin"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formattedFechaFin"
              label="Mes de finalización"
              prepend-inner-icon="mdi-calendar"
              readonly
              clearable
              hide-details
              v-bind="attrs"
              outlined
              @click:clear="setFiltroFechaFin(null), getEtapasContrato()"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fecha_fin"
            no-title
            scrollable
            @change="setFechaFin"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-select
          outlined
          label="Estado"
          :items="estados"
          item-value="value"
          item-text="label"
          hide-details
          @change="setEstado"
          clearable
          @click:clear="setFiltroEstado(null), getEtapasContrato()"
        >
        </v-select>
      </v-col>
    </v-row>

    <data-table-component
      v-if="seguimientoContrato"
      :headers="headersEtapa"
      :items="etapasList"
      v-models:select="perPageModel"
      v-models:pagina="pageModel"
      :total_registros="filters.total_rows"
      @paginar="paginar"
    >
      <template v-slot:[`item.porcentaje`]="{ item }">
        {{ (item.porcentaje * 100).toFixed(2) + "%" }}
      </template>
      <template v-slot:[`item.fecha_inicio`]="{ item }">
        {{ moment(item.fecha_inicio).format("DD/MM/YYYY hh:mm") }}
      </template>
      <template v-slot:[`item.fecha_fin`]="{ item }">
        {{ moment(item.fecha_fin).format("DD/MM/YYYY hh:mm") }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip label :color="getColor(item.estado)">
          {{ item.estado || "" }}
        </v-chip>
      </template>
      <template v-slot:[`item.puntaje`]="{ item }">
        <v-rating
          :value="parseRating(item.calificacion?.ponderacion)"
          background-color="secondary"
          color="secondary"
          x-small
          readonly
          half-increments
        ></v-rating>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          v-if="
            item.estado === 'Entregada' &&
            !haveRole('ROLE_OFICIAL_CUMPLIMIENTO')
          "
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="goToEntregas(item)" v-bind="attrs" v-on="on">
              mdi-pencil
            </v-icon>
          </template>
          <span> Editar </span>
        </v-tooltip>
        <v-tooltip v-else-if="!haveRole('ROLE_OFICIAL_CUMPLIMIENTO')" right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="goToEntregas(item)">
              mdi-clipboard-check-outline
            </v-icon>
          </template>
          <span> Configurar entrega </span>
        </v-tooltip>
      </template>
    </data-table-component>
    <div v-else class="d-flex flex-row justify-center mt-8">
      <v-progress-circular indeterminate color="primary" size="50" />
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import DataTableComponent from "@/components/DataTableComponent.vue";
import moment from "moment";
export default {
  components: {
    DataTableComponent,
  },
  props: {
    hideFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: "Etapa", align: "start", value: "etapa" },
        { text: "Fecha Inicio", value: "fecha_inicio", align: "start" },
        { text: "Estado", value: "estado", align: "start" },
        { text: "Puntaje", value: "puntaje", align: "start" },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
      menuInicio: false,
      fecha_inicio: null,
      menuFin: false,
      fecha_fin: null,
      estados: [
        { value: true, label: "Entregado" },
        { value: false, label: "Pendiente" },
        { value: null, label: "Todos" },
      ],
    };
  },
  computed: {
    ...mapState("procesoCompra", [
      "idOrdenCompra",
      "etapasList",
      "etapaProgreso",
      "filters",
    ]),
    ...mapState("seguimientoOrden", ["seguimientoContrato", "montos"]),
    headersEtapa() {
      if (this.hideFilters) {
        return this.headers.filter((target) => target.value !== "actions");
      } else return this.headers;
    },
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
    formattedFechaInicio() {
      if (this.fecha_inicio) {
        return moment(this.fecha_inicio).format("DD/MM/YYYY");
      } else return "";
    },
    formattedFechaFin() {
      if (this.fecha_fin) {
        return moment(this.fecha_fin).format("DD/MM/YYYY");
      } else return "";
    },
  },
  methods: {
    ...mapActions("procesoCompra", ["getEtapasContrato", "getEtapasProgreso"]),
    ...mapActions("seguimientoOrden", ["getEntregaContrato"]),
    ...mapMutations("procesoCompra", [
      "setFiltroFechaInicio",
      "setFiltroFechaFin",
      "setFiltroEstado",
      "setPage",
      "setPerPage",
      "limpiarEtapas",
    ]),
    ...mapMutations("seguimientoOrden", [
      "getEntregaData",
      "setEntregaEditable",
    ]),
    getColor(status_name) {
      switch (status_name) {
        case "Pendiente":
          return "pendiente";
        case "Entregada":
          return "vigente";
      }
    },
    parseRating(rating) {
      return Number(rating);
    },
    setFechaInicio() {
      this.setFiltroFechaInicio(this.fecha_inicio);
      this.getEtapasContrato();
    },
    setFechaFin() {
      this.setFiltroFechaFin(this.fecha_fin);
      this.getEtapasContrato();
    },
    setEstado(val) {
      this.setFiltroEstado(val);
      this.getEtapasContrato();
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getEtapasContrato();
    },

    createEntrega() {
      this.$router.push({
        name: "entrega-etapa-compra",
        params: {
          idContrato: this.$route.params?.idContrato,
        },
        query: {
          idProveedor: this.$route.query.idProveedor,
        },
      });
    },
    async goToEntregas(item) {
      await this.getEntregaContrato(item.id);

      this.$router.push({
        name: "entrega-etapa-compra",
        params: {
          idEtapa: Number(item.id),
          idContrato: this.$route.params?.idContrato,
        },
        query: {
          idProveedor: this.$route.query.idProveedor,
        },
      });
    },
  },

  async created() {
    this.getEtapasContrato();
  },
  beforeDestroy(){
    this.limpiarEtapas();
  },
};
</script>

<style></style>
