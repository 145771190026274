<template>
  <section class="mt-8 pb-0">
    <div class="d-flex justify-center">
      <div class="text-center mx-5">
        <p class="text-h4 mb-0">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            }).format(metricasPagos.total_adjudicado)
          }}
        </p>
        <v-card-subtitle class="justify-center my-0 py-0">
          <p class="my-0 py-0 px-6">Total Adjudicado</p>
        </v-card-subtitle>
        <v-progress-linear
          color="yellow"
          rounded
          value="100"
          class="my-0 py-0"
        ></v-progress-linear>
      </div>
      <div class="text-center mx-5">
        <p class="text-h4 mb-0">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            }).format(metricasPagos.monto_restante)
          }}
        </p>
        <v-card-subtitle class="justify-center my-0 py-0">
          <p class="my-0 py-0 px-6">Monto Restante</p>
        </v-card-subtitle>
        <v-progress-linear
          color="blue"
          rounded
          value="100"
          class="my-0 py-0"
        ></v-progress-linear>
      </div>
      <div class="text-center mx-5">
        <p class="text-h4 mb-0">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            }).format(metricasPagos.monto_cancelado)
          }}
        </p>
        <v-card-subtitle class="justify-center my-0 py-0">
          <p class="my-0 py-0 px-6">Total Cancelado</p>
        </v-card-subtitle>
        <v-progress-linear
          color="success"
          rounded
          value="100"
          class="my-0 py-0"
        ></v-progress-linear>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MetricasPagosComponet",
  props: {
    metricasPagos: {
      type: Object,
      default: () => {},
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
