<template>
  <div>
    <v-dialog :max-width="750" v-model="showModal" @click:outside="closeModal">
      <v-card>
        <v-btn class="mt-2 ml-3" icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <section class="pt-0">
          <v-row>
            <v-col cols="12" xl="5" lg="5" md="5" sm="5" xs="12" class="pb-0">
              <v-card-title class="mx-3 text-h5 secondary--text"
                >Detalle del pago</v-card-title
              >
            </v-col>
          </v-row>

          <v-card-text class="pb-0">
            <section>
              <v-row justify="center" align="center">
                <v-col cols="6">
                  <div class="d-flex flex-column">
                    <p class="font-weight-medium primary--text mb-0">
                      Fecha movimiento:
                    </p>
                    <p class="text-subtitle-1 font-weight-bold primary--text">
                      {{
                        moment(pagoDetail.fecha_hora_movimiento).format(
                          "DD/MM/YYYY hh:mm a"
                        )
                      }}
                    </p>
                    <p class="font-weight-medium primary--text mb-0">Monto:</p>
                    <p class="text-subtitle-1 font-weight-bold primary--text">
                      ${{ pagoDetail.monto }}
                    </p>
                    <p class="font-weight-medium primary--text mb-0">
                      Método de pago:
                    </p>
                    <p v-if="pagoDetail?.detalle" class="text-subtitle-1 font-weight-bold primary--text">
                      {{ pagoDetail?.detalle?.metodo_pago }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div v-if="pagoDetail?.detalle" class="d-flex flex-column mx-4">
                    <template
                      v-if="pagoDetail?.detalle?.institucion_financiera"
                    >
                      <p class="font-weight-medium primary--text mb-0">
                        Institución financiera:
                      </p>
                      <p class="text-subtitle-1 font-weight-bold primary--text">
                        {{ pagoDetail?.detalle?.institucion_financiera }}
                      </p>
                    </template>
                    <template v-if="pagoDetail?.detalle?.numero_cheque">
                      <p class="font-weight-medium primary--text mb-0">
                        Número de cheque:
                      </p>
                      <p class="text-subtitle-1 font-weight-bold primary--text">
                        {{ pagoDetail?.detalle?.numero_cheque }}
                      </p>
                    </template>
                    <template v-if="pagoDetail?.detalle?.a_nombre_de">
                      <p class="font-weight-medium primary--text mb-0">
                        A nombre de:
                      </p>
                      <p class="text-subtitle-1 font-weight-bold primary--text">
                        {{ pagoDetail?.detalle?.a_nombre_de }}
                      </p>
                    </template>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <p class="font-weight-medium primary--text mb-0">
                    Justificación:
                  </p>
                  <p class="text-subtitle-1 font-weight-bold primary--text">
                    {{ pagoDetail.justificacion }}
                  </p>
                </v-col>
              </v-row>
            </section>
          </v-card-text>
          <v-card-actions>
            <section class="pt-0">
              <v-btn class="mx-3" color="secondary" outlined @click="closeModal"
                >Cerrar</v-btn
              >
            </section>
          </v-card-actions>
        </section>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ModalDetallePagosComponent",
  data() {
    return {};
  },
  props: {
    pagoDetail: {
      type: Object,
      default: () => {},
    },
    showModal: Boolean,
  },
  computed: {
    formattedDate() {
      return this.fecha ? moment(this.fecha).format("DD/MM/YYYY") : "";
    },
    formattedHora() {
      return this.hora
        ? moment(this.hora, "HH:mm:ss aa").format("hh:mm a")
        : "";
    },
  },
  methods: {
    closeModal() {
      this.$emit("onClose");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
